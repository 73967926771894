import axios from '@axios'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchOffer(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}customer/offer/${data.token}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOfferPDF(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}customer/offer/${data.token}/pdf`, { responseType: 'arraybuffer' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    acceptOffer(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}customer/offer/${data.token}/accept`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
